.modal{
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: rgba(88, 88, 88,0.5);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;   
}
.form{
    border-style: solid;
    width: fit-content;
    height: fit-content;
    background-color: #F8FFDB;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 15px;
    width: 600px;
    row-gap: 10px;
}
.line{
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content:space-between;
}
.line>label{
    font-size: 20px;
    text-align: left;
    width: fit-content;
}
.line>input{
    font-size: 20px;
}
.line>option{
    width: 85%;
}
.mostrar{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.noMostrar{
    display: none;
}
.contenedorTabla{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 15px;
}
#caption{
    font-size: 20px;
    font-weight: 500;
}
.div-tabla{
    overflow-x: hidden;
    overflow-y: auto;
    margin-bottom: 10px;
}
.tabla{
    font-size: 18px;
    border: 1px solid;
    border-collapse: collapse;
}
td,th{
    border: 1px solid;
    padding: 3px 10px;
}
.comandos{
    display: flex;
    flex-flow: row nowrap;
    justify-content:space-evenly;
    align-items: center;
}
.btnMostrar{
    display: block;
    
}
.btnNoMostrar{
    display: none;
}
@media screen and (min-height:700px)  {
    .div-tabla{
        max-height: 500px;
    }
}

@media screen and (min-height:1000px)  {
    .div-tabla{
        max-height: 800px;
    }
}
