.login{
    display: flex;
    flex-direction: column;
    width: 600px;
    height: fit-content;
    align-items: center;
    justify-content: center;
    row-gap: 10px;
    border-style: solid;
    border-radius: 5px;
    padding: 10px;
    margin: 50px;
}
.line{
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    justify-content: space-between;
}
.line>label{
    width: 25%;
    font-size: 20px;
}
.line>input{
    width: 65%
}

