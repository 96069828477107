.reportesAdmin{
    position: fixed;
    top: 95px;
    height: fit-content;
    width: 100%;
}

.linea{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    column-gap: 15px;
}
.columna{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 15px;
}
.linea>label{
    font-size: 20px;
    text-align: left;
    width: fit-content;
}
#caption{
    font-size: 20px;
    font-weight: 500;
}
.reporte-tabla{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 15px;
    padding-left: 0;
    padding-right: 0;
    width: 100%;
    padding-bottom: 15px;
}
.div-tabla{
    overflow-x: hidden;
    overflow-y: auto;
    margin-bottom: 10px;
    width: auto;
    
}
.tabla{
    font-size: 18px;
    border: 1px solid;
    border-collapse: collapse;
    width: fit-content;
}
td,th{
    border: 1px solid;
    padding: 3px 10px;
}

@media screen and (min-height:700px)  {
    .div-tabla{
        max-height: 500px;
    }
}

@media screen and (min-height:1000px)  {
    .div-tabla{
        max-height: 800px;
    }
}
